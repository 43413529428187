<script>

    import autocompleteApi from '../../api/autocompleteApi';
    import railPlacesUtils from '../../utils/autocomplete/railPlacesUtils';
    import { mapActions, mapGetters } from 'vuex';
    import RailUtils from '../../utils/RailUtils';

    import { validationMixin } from "vuelidate";
    import { required } from "vuelidate/lib/validators";
    import dayjs from "dayjs";
    import DateUtils from "@/utils/DateUtils";

    var customParseFormat = require('dayjs/plugin/customParseFormat')
    dayjs.extend(customParseFormat)


    export default {
        components: {
        },
        mixins: [validationMixin],
        props: {
            isInPassListPage:{
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                searchTicketBtnDisabled: false,
                zeroResult: false,
                isRoundTrip: false,
                isTravelerOverMaxNum: false,
                isTravelerEmpty: false,
                validatorReturn: {
                    name: 'required',
                    length: 0
                },
                alertSettings: {
                    dismissCountDown: 0,
                    countDownSecs: 4,
                    title: '',
                    alertMsg: '',
                    alertVariant: 'warning'
                },
                passengerSelected: {
                    adult: null,
                    youth: null,
                    senior: null
                },
                countryOptions: RailUtils.getPassCountries(),
                suggestions: [],
                passCriteria: {
                    country: '',
                    countryName: '',
                    validFrom: '',
                    adult: 1,
                    senior: 0,
                    youth: 0,
                    youthAges: []
                },
                errorMsgs: {
                    fromPlace: {
                        text: "Please select your departure station"
                    },
                    toPlace: {
                        text: "Please select your arrival station"
                    },
                    departureDate: {
                        text: "Departure date is required"
                    },
                    departureTime: {
                        text: "Departure time is required"
                    },
                    returnDate: {
                        text: "Return date is required"
                    },
                    returnTime: {
                        text: "Return time is required"
                    },
                    alone: {
                        text: "Youth should not travel alone"
                    },
                    zero: {
                        text: "There should be at least one passenger"
                    },
                    country: {
                        text: "Please select a country to visit"
                    },
                    validFrom: {
                        text: "Please set a date that valid from"
                    }
                },
                tipMsgs: {
                    alone: {
                        text: "Youth should not travel alone"
                    },
                    zeroResult: {
                        text: "There is no pass for your trip"
                    },
                    maximum: {
                        text: "Max. 10 travelers"
                    }
                }
            }
        },
        validations() {
            return {
                passCriteria: {
                    country: {
                        required
                    },
                    validFrom: {
                        required
                    },
                    adult: {},
                    senior: {},
                    youth: {},
                    youthAges: {},
                }
            
            }
        },
        computed: {
            
            ...mapGetters('rail', [
                'railPassesRS',
                'railPassResults'
            ])
        
        },  
        watch: {
            railPassResults: function() {
                this.searchTicketBtnDisabled = false
                if(this.railPassResults.length === 0){
                  this.alertSettings.title = "Message"
                  this.alertSettings.alertMsg = "There is no pass for your trip"
                  this.alertSettings.alertVariant = 'warning'
                  this.alertSettings.dismissCountDown = this.alertSettings.countDownSecs+10
                }else{
                    if(!this.isInPassListPage){
                        this.$router.push({name: 'passes'})   
                    } 
                }  
            },  
            railPassesRS: function() {
                
                if(!this.railPassesRS.success){
                    this.searchTicketBtnDisabled = false

                    this.alertSettings.alertMsg = this.railPassesRS.errorTitle
                    this.alertSettings.alertMsg = this.railPassesRS.errorMsg
                    this.alertSettings.alertVariant = 'danger'
                    this.alertSettings.dismissCountDown = this.alertSettings.countDownSecs+10

                }
            },
        },
        methods: {
            ...mapActions("rail", [
                "searchTrainTickets",
                "searchRailPasses",
                "updateSearchCriteria",
            ]),
            countDownChanged(dismissCountDown) {
                this.alertSettings.dismissCountDown = dismissCountDown
            },
            validatePassState(field) {
                const { $dirty, $error } = this.$v.passCriteria[field];
                return $dirty ? !$error : null;
            },
            validatePassPassengers(errorCause) {
                if(this.passCriteria.adult + this.passCriteria.senior + this.passCriteria.youth > 10){
                    this.isTravelerOverMaxNum = true
                }else{
                    this.isTravelerOverMaxNum = false
                }

                if(this.$v.passCriteria.$dirty){
                    let isTravelAlone = (this.passCriteria.adult == 0) && (this.passCriteria.senior == 0)
                    && (this.passCriteria.youth > 0)
                    let isAllZero = (this.passCriteria.adult == 0) && (this.passCriteria.senior == 0)
                    && (this.passCriteria.youth == 0)

                    if(errorCause === 'alone') return isTravelAlone
                    if(errorCause === 'zero') return !isAllZero

                    return null
                }
                return null
            },
            // str is the input value of from/to input field
            getSuggestedStations(str) {
                autocompleteApi.railPlaces(str).then(res => {
                    let items = res.data
                    let destinations = railPlacesUtils.dataSuggestions(items)
                    this.suggestions = destinations
                });
                
            },
            validateDate(fieldName){
                let fieldValue = this.passCriteria[fieldName];
                let isValid = dayjs(fieldValue, 'YYYY-MM-DD', true).isValid();

                if (isValid) {
                    let today = new Date(DateUtils.format(new Date()));
                    if (this.passCriteria.validFrom!=null && this.passCriteria.validFrom!=''){
                        let date = new Date(this.passCriteria.validFrom).getTime();
                        if (date < today){
                            this.passCriteria.validFrom = ''
                        }
                    }
                    return;
                }

                this.passCriteria.validFrom = ''
            },
            searchPasses () {
                this.zeroResult = false
                let isAllOkToGo = true
                this.$v.$touch()
                this.isTravelerEmpty = (this.passCriteria.adult == 0) && (this.passCriteria.senior == 0)
                    && (this.passCriteria.youth == 0)

              if (this.isTravelerEmpty){
                this.alertSettings.title = "Message"
                this.alertSettings.alertMsg = "There should be at least one passenger"
                this.alertSettings.alertVariant = 'danger'
                this.alertSettings.dismissCountDown = this.alertSettings.countDownSecs+10
              }

                isAllOkToGo = !this.$v.passCriteria.$invalid && !this.isTravelerOverMaxNum && !this.isTravelerEmpty
                if (isAllOkToGo) {
                    let passCriteriaCopy = JSON.parse(JSON.stringify(this.passCriteria));

                    this.updateSearchCriteria({
                        passCriteria: passCriteriaCopy,
                    })

                    this.searchTicketBtnDisabled = !this.searchTicketBtnDisabled
                    sessionStorage.setItem("passCriteria", JSON.stringify(this.passCriteria))
                    this.searchRailPasses({passCriteria: this.passCriteria})
                    this.$emit("reSearch");
                }
            },  
            generatePassAgeFields(childNumber) {
                let itemsAddedOrRemoved = childNumber - this.passCriteria.youthAges.length
                for(let i = 0; i < Math.abs(itemsAddedOrRemoved); i++) {
                    if(itemsAddedOrRemoved > 0) {
                        this.passCriteria.youthAges.push({'age': 0})
                    }else if(itemsAddedOrRemoved < 0) {
                        this.passCriteria.youthAges.pop()
                    }
                    
                }
                
            },
        },
        created() {
            if(sessionStorage.getItem("passCriteria") != null && sessionStorage.getItem("passCriteria") != undefined){
                this.passCriteria = JSON.parse(sessionStorage.getItem("passCriteria"))
            }
        }
        
    }
</script>

<template>
    <b-container>
        <b-row>
            <b-col cols="6">
                <b-form-group>
                    <b-form-select 
                    v-model="$v.passCriteria.country.$model"
                    :options="countryOptions"
                    text-field="label"
                    value-field="code"
                    placeholder="Country To Visit"
                    :state="validatePassState('country')"
                    :aria-describedby="'country-feedback'"
                    ></b-form-select>
                    <b-form-invalid-feedback id="country-feedback">
                        {{errorMsgs.country.text}}</b-form-invalid-feedback>
                </b-form-group>
            </b-col>
            <b-col cols="5">
                <b-input-group :class="{ 'form-group--error': $v.passCriteria.validFrom.$error }">
                    <b-input-group-prepend>
                        <b-form-datepicker placeholder="Valid From"
                                           :min="new Date()"
                                           v-model="$v.passCriteria.validFrom.$model"
                                           show-decade-nav
                                           button-only
                                           @input="validateDate('validFrom')"
                                           aria-describedby="departure-date-feedback"
                        ></b-form-datepicker>
                    </b-input-group-prepend>
                    <b-form-input
                        id="example-input"
                        v-model="$v.passCriteria.validFrom.$model"
                        type="text"
                        placeholder="Valid From-YYYY-MM-DD"
                        autocomplete="off"
                        @blur="validateDate('validFrom')"
                    ></b-form-input>
                </b-input-group>
                <span v-if="$v.passCriteria.validFrom.$error" class="error-message">
                       {{errorMsgs.validFrom.text}}
                </span>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="2">
                <b-form-group label="Adult(28-59)">
                    <b-form-spinbutton v-model="passCriteria.adult" 
                    :max="10" :min="0"
                    placeholder="Adult(28-59)"
                    aria-describedby="pass-adult-feedback"
                    ></b-form-spinbutton>
                    <b-form-invalid-feedback id="pass-adult-feedback">
                            </b-form-invalid-feedback>
                    <b-form-invalid-feedback id="pass-max-num-feedback"
                    :state="!isTravelerOverMaxNum"
                    >{{tipMsgs.maximum.text}}</b-form-invalid-feedback>
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group label="Senior(60+)">
                    <b-form-spinbutton v-model="passCriteria.senior" 
                    :max="10" :min="0"
                    placeholder="Senior(60+)"
                    aria-describedby="pass-senior-feedback"
                    ></b-form-spinbutton>
                    <b-form-invalid-feedback id="pass-senior-feedback">
                            </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group label="Youth/Child(0-27)">
                    <b-form-spinbutton v-model="passCriteria.youth" 
                    :max="10" :min="0"
                    placeholder="Youth/Child(0-27)"
                    @change="generatePassAgeFields"
                    aria-describedby="pass-youth-feedback"
                    ></b-form-spinbutton>
                    <b-form-valid-feedback id="pass-youth-feedback">
                            </b-form-valid-feedback>
                </b-form-group>
            </b-col>
            <b-col v-if="passCriteria.youthAges.length > 0">
                <b-form-group label="Age">
                <div class="d-flex">
                    <b-form-input 
                    class="mx-1 ageField-max-width"
                    type="number"
                    v-for="item, index in passCriteria.youthAges"
                    placeholder="Age"
                    :max="27"
                    :min="0"
                    v-model="item.age"
                    :key="'child-age-'+index"
                    ></b-form-input>
                </div>
                </b-form-group>
                <p class="m-2" v-show="(passCriteria.youthAges.length > 0)">
                    Enter the ages of youths on the day of travel 
                    <i class="dripicons-question" v-b-tooltip.hover id="youth-age-tooltip"></i>
                    <b-tooltip target="youth-age-tooltip" triggers="hover">
                        
                    </b-tooltip>
                </p>
                
            </b-col>
            <!-- <b-col>
                <b-form-spinbutton 
                class="mx-1"
                vertical
                v-for="item, index in passCriteria.youthAges"
                placeholder="Age"
                :max="27"
                :min="0"
                v-model="item.age"
                :key="'child-age-'+index"
                ></b-form-spinbutton>
                <p class="m-2" v-show="(passCriteria.youthAges.length > 0)">
                    Enter the ages of youths on the day of travel 
                    <i class="dripicons-question" v-b-tooltip.hover id="youth-age-tooltip"></i>
                    <b-tooltip target="youth-age-tooltip" triggers="hover">
                        
                    </b-tooltip>
                </p>
            </b-col> -->
            
        </b-row>
        <b-row class="text-center">
            <!-- <b-form-valid-feedback id="pass-alone-feedback"
            :state="validatePassPassengers('alone')"
            >{{tipMsgs.alone.text}}</b-form-valid-feedback> -->

<!--            <b-form-invalid-feedback id="pass-zero-feedback"-->
<!--            :state="validatePassPassengers('zero')"-->
<!--            >{{errorMsgs.zero.text}}</b-form-invalid-feedback>-->

<!--            <b-form-valid-feedback class="text-center" id="zero-result-feedback"-->
<!--                :state="zeroResult">-->
<!--                {{tipMsgs.zeroResult.text}}</b-form-valid-feedback>-->
        </b-row>        

        <b-row align-h="center"
		>
                <b-button variant="success" @click="searchPasses" :disabled="searchTicketBtnDisabled">
                <template >
                    <b-spinner small v-if="searchTicketBtnDisabled"></b-spinner>
                    <span class="">{{searchTicketBtnDisabled ? 'Loading...' : 'Search For Passes'}}</span>
                </template>
            </b-button>
        </b-row>
        <b-alert
            :show="alertSettings.dismissCountDown"
            class="position-fixed fixed-top m-0 rounded-0 text-center"
            style="z-index: 2000;"
            :variant="alertSettings.alertVariant"
            dismissible
            @dismiss-count-down="countDownChanged"
            >
<!--                <div v-html="alertSettings.title"></div>-->
                <div v-html="alertSettings.alertMsg"></div>
        </b-alert>
    </b-container>
</template>



<style scoped>
.ageField-max-width{
    min-width: 70px !important;
    max-width: 70px !important;
}
</style>